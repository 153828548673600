import Image from "next/image";
import LogoWhite from "@/assets/images/logo_white.png";
import LogoBlack from "@/assets/images/logo_black.png";
import { useTheme } from "next-themes";

export const Logo = () => {
  const theme = useTheme();
  const isDarkMode = theme.resolvedTheme === "dark";
  const src = isDarkMode ? LogoWhite : LogoBlack;
  const alt = "QuiklyAI Logo";
  return <Image src={src} alt={alt} width={20} height={20} />;
};
