import { type SiteConfig } from "../types";

export const siteConfig: SiteConfig = {
  name: "Quikly",
  description:
    "Quikly is an advanced AI document knowledge base designed to enhance sales engineering and customer support with immediate, AI-driven answers and insights.",
  url: "https://quikly.ai", // Adjust to your actual URL
  ogImage: "https://quikly.ai/og-image.jpg", // Adjust to your actual Open Graph image URL
  links: {
    twitter: "https://twitter.com/QuiklyAI", // Adjust to your actual Twitter handle if you have one
    github: "https://github.com/YourOrg/quikly-app", // Adjust to the GitHub URL of your project
    // Add any other social or important links related to QuiklyAI here
  },
};
