"use client";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

import { type MainNavItem } from "../types";
import { siteConfig } from "@/config/site";
import { cn } from "@/lib/utils";
import { Icons } from "@/components/icons";
import { MobileNav } from "@/components/mobile-nav";
import { Logo } from "./logo";
import { Badge } from "./ui/badge";

interface MainNavProps {
  items?: MainNavItem[];
  children?: React.ReactNode;
}

export function MainNav({ items, children }: MainNavProps) {
  const router = useRouter();
  const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);
  const segment = router.pathname.split("/")[1];

  return (
    <div className="flex gap-6 md:gap-10">
      <Link href="/" className="hidden items-center space-x-2 md:flex">
        <Logo />
        <div className="justify-center flex">
          <span className="hidden font-bold sm:inline-block m-auto">
            {siteConfig.name}
          </span>
          <Badge
            style={{
              fontSize: 6,
              width: 20,
              height: 10,
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              margin: "15px 5px 0 0",
            }}
          >
            BETA
          </Badge>
        </div>
      </Link>
      {items?.length ? (
        <nav className="hidden gap-6 md:flex">
          {items.map((item, index) => (
            <Link
              key={index}
              href={item.disabled ? "#" : item.href}
              className={cn(
                "flex items-center text-lg font-medium transition-colors hover:text-foreground/80 sm:text-sm",
                item.href.startsWith(`/${segment}`)
                  ? "text-foreground"
                  : "text-foreground/60",
                item.disabled && "cursor-not-allowed opacity-80",
              )}
            >
              {item.title}
            </Link>
          ))}
        </nav>
      ) : null}
      <button
        className="flex items-center space-x-2 md:hidden"
        onClick={() => setShowMobileMenu(!showMobileMenu)}
      >
        {showMobileMenu ? <Icons.close /> : <Logo />}
        <span className="font-bold">Menu</span>
      </button>
      {showMobileMenu && items && (
        <MobileNav items={items}>{children}</MobileNav>
      )}
    </div>
  );
}
